import React from "react";
import { Input } from "antd";
import * as Constants from "../../../shared/Constants";

const { TextArea } = Input;

export const CustomInput = (props) => {
  const errorclass = props.errorclass ? "input-error" : "";
  return (
    <span className={errorclass}>
      <TextArea
        autoSize={{ minRows: 1, maxRows: 3 }}
        {...props}
        className={
          props.overrideStyle
            ? props.className
            : "input-field" + " " + props.className
        }
        onChange={(e) => {
          if (e.target.value !== props.value) {
            props.onChange(e);
          }
        }}
        maxLength={
          props.maxLength && props.overrideStyle
            ? props.maxLength
            : Constants.INPUT_MAX_LENGTH
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </span>
  );
};
