import { useEffect } from "react";

export const useHideScroll = (show, name) => {
  useEffect(() => {
    // Disable root container scrolling when modal is open
    const rootElm = document.getElementsByTagName("body")[0];
    if (show) {
      rootElm.classList.add(name);
    } else {
      rootElm.classList.remove(name);
    }
    return () => {
      rootElm.classList.remove(name);
    };
  }, [show, name]);
  return;
};
