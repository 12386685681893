import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Notify } from "react-redux-notify";
import "react-redux-notify/dist/ReactReduxNotify.css";
import { createNotification, NOTIFICATION_TYPE_INFO } from "react-redux-notify";
import Blank from "../../components/blank/Blank.js";
import BlankShow from "../../components/blank/BlankShow";
import Searchbox from "../../utils/searchbox/Searchbox";
import Header from "../../pages/layouts/header/Header";
import Backdrop from "../../components/shared/UI/Backdrop/Backdrop";
import LiveShotContainer from "../LiveShotContainer/LiveShotContainer";
import Spinner from "../../components/shared/UI/Spinner/Spinner";
import CommanModal from "../../components/shared/UI/commanModal/CommanModal";
import ViewRoleAs from "../../components/rolechange/ViewRoleAs";
import SignalModal from "../../components/modals/signalModal/SignalModal";
import * as actions from "../../store/actions/index";
import NotificationModal from "../../components/modals/notifiactionModal/NotificationModal.js";
import Button from "../../components/shared/UI/Button/Button";
import SignalErrorModal from "../../components/modals/signalErrorModal/SignalErrorModal";
import { removeDuplicates } from "../../components/shared/utility";
import { useUser } from "../../hooks/useUser.js";
import NotificationBarComponent from "../../components/shared/UI/NotificationBarComponent/NotificationBarComponent.js";
import { ReactComponent as Info } from "../../assets/icons/Info.svg";

// Per https://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366/44873576#8619946
const getDayOfYear = () => {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

const TIPS_AND_TRICKS = window.$TIPS_AND_TRICKS;
let todaysTipTrick = "";
if (TIPS_AND_TRICKS !== undefined) {
  let tipsTricksLen = TIPS_AND_TRICKS.length;
  let dayOfYear = getDayOfYear();
  let todaysTipTrickIdx = dayOfYear % tipsTricksLen;
  // console.info(
  //   "Showing TipTrick: " + todaysTipTrickIdx + " of " + tipsTricksLen
  // );

  if (todaysTipTrickIdx >= 0 && todaysTipTrickIdx < tipsTricksLen)
    todaysTipTrick = TIPS_AND_TRICKS[todaysTipTrickIdx];
}

const flashNotification = {
  message: todaysTipTrick,
  type: NOTIFICATION_TYPE_INFO,
  duration: parseInt(process.env.REACT_APP_FLASH_NOTIF_DUR),
  canDismiss: true,
  icon: <Info />,
  customStyles: {
    "notification--info": "custom-notif",
    content: "custom-notif-content",
  },
};

const Home = (props) => {
  const { takeAllPerm, grabAllPerm } = useUser();
  const {
    normalLiveShotTypes,
    currentCanvasId,
    normalLiveShots,
    blankCanvasShow,
    loading,
    onCloseCommanModal,
    successMsg,
    showModal,
    showSearch,
    onCloseSearchModal,
    onShowSearchModal,
    isAuthenticated,
    onFetchCurrentUserRole,
    onFetchAllLiveshotPreference,
    token,
    currentUser,
    currentUserRole,
    notifications,
    showSignalModal,
    onHideSignalModal,
    signalMsg,
    showBorderMsg,
    showCrPathMsg,
    nonGrabNotifications,
    currentShow,
    onCheckCanvas,
    showDate,
    userList,
    liveshotsInfo,
    onSearchCurrentLiveShot,
    createNotification,
    onFetchStudioTruckList,
    onFetchHeartBeat,
    onFetchRoleList,
    liveShotSignalSelected,
    currentCanvas,
    routingCapabilityType,
    onTakeAllCanvasBlock,
    onGrabAllCanvasBlock,
    showErrorModal,
    onHideSignalErrorValueModal,
    onSetActiveLiveshot,
    controlRoomList,
    isArmRouting,
    inBoundCRPath,
    outBoundCRPath,
    ifbCRPath,
    plCRPath,
    phoCRPath,
    shortCut,
    streamDeckOn,
    onStoreArrPosition,
    userRole,
    authRemove,
    notificationBarDetails,
  } = props;

  let isAlertEnableByProp = localStorage.getItem("isAlertEnable");

  const [isAlert, setIsAlert] = useState(true);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModalType, setNotificationModalType] =
    useState("refresh_data");
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [takeGrabBtn, setTakeGrabBtn] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);

  const onCloseSearch = () => {
    onCloseSearchModal();
  };

  const onShowSearchBox = () => {
    onShowSearchModal();
    onSearchCurrentLiveShot(token);
  };

  const onClosePopUp = () => {
    onCloseCommanModal(currentCanvasId, token);
  };

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  useEffect(() => {
    setIsAlert(isAlertEnableByProp && isAlertEnableByProp == "true");
  }, [isAlertEnableByProp]);

  useEffect(() => {
    onFetchAllLiveshotPreference(token);
  }, [token, onFetchAllLiveshotPreference]);

  useEffect(() => {
    if (!shortCut && !streamDeckOn) {
      onStoreArrPosition(0);
    }
  }, [shortCut, streamDeckOn, onSetActiveLiveshot, onStoreArrPosition]);

  const [commonNotificationDetails, setCommonNotificationDetails] = useState(
    {}
  );
  useEffect(() => {
    if (!notificationBarDetails.onClose) {
      notificationBarDetails.onClose = () => {
        setCommonNotificationDetails({ show: false });
      };
    }
    setCommonNotificationDetails(notificationBarDetails);
  }, [notificationBarDetails]);

  let heartBeatInterval;
  useEffect(() => {
    let seconds = process.env.REACT_APP_HEARTBEAT_PERIOD
      ? process.env.REACT_APP_HEARTBEAT_PERIOD
      : 120000;
    if (
      currentUserRole &&
      currentUserRole.roleId !== 0 &&
      currentUserRole.roleId !== undefined
    ) {
      heartBeatInterval = setInterval(() => {
        onFetchHeartBeat(userId, token, onSessionTimeOut);
      }, seconds);
    }
    setCommonNotificationDetails({ show: false });
    return () => {
      clearInterval(heartBeatInterval);
    };
  }, []);

  useEffect(() => {
    let milliSeconds = process.env.REACT_APP_SESSION_TIMEOUT
      ? process.env.REACT_APP_SESSION_TIMEOUT
      : 28800000;
    let lastActive = Date.now();

    const resetTimer = () => {
      lastActive = Date.now();
    };

    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    let lastInactivetimeout = setInterval(function () {
      if (Date.now() - lastActive > milliSeconds) {
        console.log(
          "session timed out after " +
            Math.floor((Date.now() - lastActive) / 1000) +
            " seconds"
        );
        onSessionTimeOut();
        clearInterval(lastInactivetimeout);
      }
    }, 1000);

    // time out even if user is active after some time
    // let timeout = setTimeout(() => {
    //   onSessionTimeOut();
    // }, 1000 * seconds);

    return () => {
      clearInterval(lastInactivetimeout);
      // clearInterval(timeout);
    };
  }, []);

  const onSessionTimeOut = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("currentUser");
    setNotificationModalType("sessionTimeout");
    setShowNotificationModal(true);
    clearInterval(heartBeatInterval);
  };

  useEffect(() => {
    if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
      onFetchCurrentUserRole(userId, token);
    }
    if (isAuthenticated) {
      onFetchCurrentUserRole(userId, token);
    }
    if (TIPS_AND_TRICKS !== undefined && TIPS_AND_TRICKS.length > 0) {
      createNotification(flashNotification);
    }
  }, [
    isAuthenticated,
    props.history,
    onFetchCurrentUserRole,
    currentUser,
    token,
    userId,
    createNotification,
  ]);

  useEffect(() => {
    onFetchStudioTruckList(token);
  }, [onFetchStudioTruckList, token]);

  useEffect(() => {
    if (currentUserRole.roleName === "View_Only_Alt") {
      onFetchRoleList(token);
    }
  }, [onFetchRoleList, token, currentUserRole]);

  let showId = currentShow !== "" ? currentShow.showId : null;

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  useEffect(() => {
    if (routingCapabilityType >= 2) {
      setTakeGrabBtn(true);
    } else {
      setTakeGrabBtn(false);
    }
  }, [routingCapabilityType, takeGrabBtn]);

  const reloadSession = () => {
    setShowNotificationModal(false);
    authRemove();
  };

  useEffect(() => {
    if (isAlert && notifications) {
      handleWebSocketNotifiation(notifications);
    }
  }, [notifications, userId]);

  const isCurrentCanvasUpdated = (type, data) => {
    if (type == "CANVAS") {
      return currentCanvasId == data;
    }
    if (type == "BLOCK") {
      return currentCanvasId == data.canvasId;
    }
    if (type == "LIVESHOT") {
      let blockIdList = normalLiveShots.map((item) => item.blockId);
      return blockIdList.includes(data.blockId);
    }
    return false;
  };

  const handleWebSocketNotifiation = (notification) => {
    if (userId && notification && notification.type && notification.data) {
      if (!["CANVAS", "BLOCK", "LIVESHOT"].includes(notification.type)) {
        return;
      }

      if (!isCurrentCanvasUpdated(notification.type, notification.data)) {
        return;
      }

      let updatedBy = notification.data.updatedBy || notification.updatedBy;
      if (parseInt(userId) == parseInt(updatedBy)) {
        return;
      }

      const userDetails = userList.find(
        (element) => element.sso === parseInt(updatedBy)
      );
      let userName =
        userDetails && userDetails.userName ? userDetails.userName : updatedBy;

      let message = `The Canvas you are editing has been updated by ${userName}, please refresh to see latest details`;
      let notificationDetails = {
        show: true,
        content: message,
        onClose: () => {
          setCommonNotificationDetails({ show: false });
        },
        isWarning: true,
        timeoutMs: 0,
        onRefresh: () => {
          let showId = currentShow !== "" ? currentShow.showId : null;
          onCheckCanvas(token, showDate, showId);
          setCommonNotificationDetails({ show: false });
        },
      };
      setCommonNotificationDetails(notificationDetails);
    }
  };

  let canvas =
    blankCanvasShow !== true ||
    normalLiveShotTypes.length > 0 ||
    (normalLiveShots.length > 0 &&
      normalLiveShots.filter((normalLiveShot) => normalLiveShot.blockId)
        .length > 0) ? (
      <LiveShotContainer />
    ) : (
      <Blank />
    );

  if (showId === null) {
    canvas = <BlankShow />;
  }

  let notificationModal = null;
  if (showNotificationModal) {
    if (notificationModalType === "sessionTimeout") {
      notificationModal = (
        <Fragment>
          <Backdrop show={true} />
          <NotificationModal
            type={notificationModalType}
            onConfirm={reloadSession}
          />
        </Fragment>
      );
    }
  }

  let searchBox = null;
  if (showSearch) {
    searchBox = (
      <Fragment>
        <Backdrop show={showSearch} clicked={onCloseSearch} />
        <Searchbox currentUserRole={currentUserRole} />
      </Fragment>
    );
  }

  let showSignalModalPanel = null;
  if (showSignalModal) {
    showSignalModalPanel = (
      <Fragment>
        <SignalModal
          showSignalModal={showSignalModal}
          closeModal={onHideSignalModal}
          signalInfo={signalMsg}
          crMsg={showCrPathMsg}
        />
      </Fragment>
    );
  }

  let showSignalErrorModalPanel = null;
  if (showErrorModal) {
    showSignalErrorModalPanel = (
      <Fragment>
        <Backdrop
          show={showSignalModal}
          clicked={onHideSignalErrorValueModal}
        />
        <SignalErrorModal
          closeModal={onHideSignalErrorValueModal}
          signalInfo={signalMsg}
          showBorderMsg={showBorderMsg}
        />
      </Fragment>
    );
  }

  let commanModal = null;
  if (showModal) {
    commanModal = (
      <Fragment>
        <Backdrop show={showModal} clicked={onClosePopUp} />
        <CommanModal closeModal={onClosePopUp} message={successMsg} />
      </Fragment>
    );
  }
  if (loading) {
    canvas = <Spinner />;
  }

  const onClickTakeAll = (key) => {
    let showMessage = false;
    if (liveShotSignalSelected.length > 0) {
      if (
        normalLiveShots.length > 0 &&
        normalLiveShots.filter(
          (normalLiveShot) => normalLiveShot.liveshots.length > 0
        ).length > 0
      ) {
        let normalLiveshot;
        let normalLiveshotFull = [];
        if (normalLiveShots && normalLiveShots.length > 0) {
          normalLiveShots
            .filter((normalLiveShot) => {
              return normalLiveShot.softDelete !== true;
            })
            .forEach((normalLiveShot, index) => {
              const liveshots = normalLiveShot.liveshots;
              let filteredLiveshot = liveshots.filter(
                (liveshot) =>
                  liveshot.readyForPcr === true || liveshot.readyForAir === true
              );
              filteredLiveshot.forEach((liveshot) => {
                normalLiveshotFull.push(liveshot);
              });
            });
          normalLiveshot = normalLiveshotFull;
        }
        let liveshots;
        liveshots = normalLiveshot;
        let abstractArray = [];
        let normalArray = [];
        let signalArr,
          normalSignalInfo,
          outBoundFields,
          outBoundFieldsNormal,
          commsFields,
          commsFieldsNormal;
        for (let index in liveshots) {
          signalArr = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.abstractLiveshotId ===
                liveshots[index].abstractLiveshot.abstractLiveshotId &&
              signal.liveshotSignalTypeId === 1
          );
          normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.liveshotId === liveshots[index].liveshotId &&
              signal.liveshotSignalTypeId === 0
          );
          for (let key in signalArr) {
            const signal = signalArr[key];
            const normalSignal = normalSignalInfo.find(
              (item) =>
                item.abstractLiveshotSignalId === signal.liveshotSignalId
            );
            normalArray.push(signal);
            normalSignal && abstractArray.push(normalSignal);
          }
        }
        let liveshotWithOutGlobalPath = normalArray.filter(
          (signal) => signal.liveshotId === 0 && signal.globalPathName === ""
        );
        let liveshotNormalSignal = normalArray.filter(
          (signal) => signal.liveshotId === 0 && signal.globalPathName
        );
        let liveshotAbstractUnilateralSignal = normalArray.filter(
          (signal) => signal.liveshotId === 0 && signal.uniLateral === false
        );
        let liveshotSignalNormal = liveshotNormalSignal.concat(
          liveshotAbstractUnilateralSignal
        );
        let liveshotAbstractWithOutGlobalPath = abstractArray.filter(
          (signal) =>
            signal.abstractLiveshotId === 0 && signal.crPathName === ""
        );
        let liveshotAbstractSignal = abstractArray.filter(
          (signal) =>
            signal.abstractLiveshotId === 0 && signal.crPathName !== ""
        );
        let liveshotSignalAbstarct = liveshotAbstractSignal.filter(
          (signal, ind) =>
            ind ===
            liveshotAbstractSignal.findIndex(
              (elem) =>
                elem.liveshotSignalTypeId === signal.liveshotSignalTypeId &&
                elem.crPathName === signal.crPathName
            )
        );
        let liveshotSignalAbstarctDuplicate = liveshotAbstractSignal.filter(
          (signal, ind) =>
            ind !==
            liveshotAbstractSignal.findIndex(
              (elem) => elem.crPathName === signal.crPathName
            )
        );
        let liveshotSignalFiltered = liveshotSignalNormal.concat(
          liveshotSignalAbstarct,
          liveshotWithOutGlobalPath,
          liveshotAbstractWithOutGlobalPath
        );
        let liveshotSignalDuplicates = liveshotSignalAbstarctDuplicate;
        if (liveshotSignalDuplicates.length > 0) {
          for (let key in liveshotSignalDuplicates) {
            let duplicateSignal = liveshotSignalDuplicates[key];
            if (duplicateSignal.liveshotId === 0) {
              let signal = liveShotSignalSelected.filter(
                (signal) =>
                  signal.abstractLiveshotSignalId ===
                  duplicateSignal.liveshotSignalId
              );
              duplicateSignal.crPathName = signal[0].crPathName;
            } else {
              let signal = liveShotSignalSelected.filter(
                (signal) =>
                  signal.liveshotSignalId ===
                  duplicateSignal.abstractLiveshotSignalId
              );
              duplicateSignal.globalPathName = signal[0].globalPathName;
            }
          }
        }

        for (let index in liveshots) {
          signalArr = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.abstractLiveshotId ===
                liveshots[index].abstractLiveshot.abstractLiveshotId &&
              signal.liveshotSignalTypeId === 1
          );
          normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.liveshotId === liveshots[index].liveshotId &&
              signal.liveshotSignalTypeId === 0
          );
          for (let key in signalArr) {
            const signal = signalArr[key];
            const normalSignal =
              normalSignalInfo.find(
                (item) =>
                  item.abstractLiveshotSignalId === signal.liveshotSignalId
              ) || {};
            let inBoundCrPathInfo =
              inBoundCRPath.find((item) => item === normalSignal.crPathName) ||
              {};
            if (normalSignal.crPathName === "" || !inBoundCrPathInfo) {
              showMessage = true;
            }
          }

          outBoundFields = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.abstractLiveshotId ===
                liveshots[index].abstractLiveshot.abstractLiveshotId &&
              signal.liveshotSignalTypeId === 2
          );
          outBoundFieldsNormal = removeDuplicates(
            liveShotSignalSelected
          ).filter(
            (signal) =>
              signal.liveshotId === liveshots[index].liveshotId &&
              signal.liveshotSignalTypeId === 0
          );
          for (let key in outBoundFields) {
            const signal = outBoundFields[key];
            const normalSignal =
              outBoundFieldsNormal.find(
                (item) =>
                  item.abstractLiveshotSignalId === signal.liveshotSignalId
              ) || {};
            let outBoundCrPathInfo =
              outBoundCRPath.find((item) => item === normalSignal.crPathName) ||
              {};
            if (normalSignal.crPathName === "" || !outBoundCrPathInfo) {
              showMessage = true;
            }
          }

          commsFields = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.abstractLiveshotId ===
                liveshots[index].abstractLiveshot.abstractLiveshotId &&
              (signal.liveshotSignalTypeId === 5 ||
                signal.liveshotSignalTypeId === 8 ||
                signal.liveshotSignalTypeId === 9)
          );
          commsFieldsNormal = removeDuplicates(liveShotSignalSelected).filter(
            (signal) =>
              signal.liveshotId === liveshots[index].liveshotId &&
              signal.liveshotSignalTypeId === 0
          );
          for (let key in commsFields) {
            const signal = commsFields[key];
            const normalSignal =
              commsFieldsNormal.find(
                (item) =>
                  item.abstractLiveshotSignalId === signal.liveshotSignalId
              ) || {};
            let commCrPathInfo = true;
            const commCRArray = [...ifbCRPath, ...plCRPath, ...phoCRPath];
            commCrPathInfo =
              commCRArray.find((item) => item === normalSignal.crPathName) ||
              {};
            if (normalSignal.crPathName === "" || !commCrPathInfo) {
              showMessage = true;
            }
          }
        }

        if (key === "take") {
          onTakeAllCanvasBlock(
            token,
            userId,
            userFullName,
            liveshots,
            liveShotSignalSelected,
            currentCanvas.roomId,
            currentRoom,
            liveshotSignalDuplicates,
            showMessage
          );
        } else {
          onGrabAllCanvasBlock(
            token,
            userId,
            userFullName,
            liveshots,
            liveShotSignalSelected,
            currentCanvas.roomId,
            showMessage,
            currentRoom,
            currentCanvas,
            outBoundFieldsNormal,
            commsFieldsNormal
          );
        }
      }
    }
  };

  let notAllowTakeAll = false;
  let notAllowGrabAll = false;
  if (isArmRouting === false || !takeAllPerm) {
    notAllowTakeAll = true;
  }
  if (isArmRouting === false || !grabAllPerm) {
    notAllowGrabAll = true;
  }

  let canvasTakeAndGrab = null;
  if (takeGrabBtn && currentCanvas.isRoomSelected) {
    canvasTakeAndGrab = (
      <div className="canvas-block-btns">
        <div className="canvasblock-Take-Grab">
          <span className="title-canvas">Canvas</span>
          <span className="take-grab-btns">
            <Button
              type="button"
              btnType="takeall"
              onClick={() => onClickTakeAll("take")}
              disabled={notAllowTakeAll}
            >
              USE
            </Button>
            <Button
              type="button"
              btnType="graball"
              onClick={() => onClickTakeAll("grab")}
              disabled={notAllowGrabAll}
            >
              OWN
            </Button>
          </span>
        </div>
      </div>
    );
  }
  let roleChange =
    userRole.roleName === "Super Admin" ? (
      <ViewRoleAs currentUserRoleId={currentUserRole.roleId} />
    ) : null;

  return (
    <Fragment>
      <NotificationBarComponent
        show={commonNotificationDetails.show}
        errorMessage={commonNotificationDetails.content}
        closeErrorBar={commonNotificationDetails.onClose}
        isError={commonNotificationDetails.isError}
        isWarning={commonNotificationDetails.isWarning}
        isFixed={true}
        timeoutMs={commonNotificationDetails.timeoutMs}
        onRefresh={commonNotificationDetails.onRefresh}
      ></NotificationBarComponent>
      {commanModal}
      {searchBox}
      {showSignalModalPanel}
      {showSignalErrorModalPanel}
      {notificationModal}
      <Notify />
      <Header
        searchBoxShow={onShowSearchBox}
        searchButtonClicked={onShowSearchBox}
        canvasTakeAllHandler={onClickTakeAll}
        roleChange={roleChange}
      />
      <div className="container-canvasTakeGrab"> {canvasTakeAndGrab}</div>
      {canvas}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    normalLiveShotTypes: state.liveShotContainer.normalLiveShotTypes,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    successMsg: state.liveShotInfo.successMsg,
    showModal: state.liveShotInfo.showModal,
    showSearch: state.searchListInfo.showSearch,
    normalLiveShots: state.liveShotContainer.normalLiveShots,
    blankCanvasShow: state.liveShotContainer.blankCanvasShow,
    loading: state.liveShotContainer.loading,
    isAuthenticated: state.auth.accessToken !== "",
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    notifications: state.websocketInfo.socketMsg,
    signalMsg: state.signalsInfo.signalMsg,
    showSignalModal: state.signalsInfo.showSignalModal,
    showBorderMsg: state.signalsInfo.showBorderMsg,
    showCrPathMsg: state.signalsInfo.showCrPathMsg,
    nonGrabNotifications: state.websocketInfo.nongrabMsg,
    showDate: state.canvasInfo.showDate,
    currentShow: state.showInfo.currentShow,
    liveshotsInfo: state.liveShotContainer.liveshotsInfo,
    userList: state.user.userList,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    currentCanvas: state.canvasInfo.currentCanvas,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    showErrorModal: state.signalsInfo.showErrorModal,
    controlRoomList: state.canvasInfo.controlRoomList,
    isArmRouting: state.canvasInfo.armRouting,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    shortCut: state.canvasInfo.shortCut,
    streamDeckOn: state.canvasInfo.streamDeckOn,
    userRole: state.user.userRole,
    notificationBarDetails: state.notificationInfo.notificationBarDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseCommanModal: (canvasId, token) =>
      dispatch(actions.closeCommanModal(canvasId, token)),
    onCloseSearchModal: () => dispatch(actions.closeSearchModal()),
    onShowSearchModal: () => dispatch(actions.openSearchModal()),
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
    onShowNotificationModal: () => dispatch(actions.openNotificationModal()),
    onHideSignalModal: () => dispatch(actions.hideSignalModal()),
    onHideSignalErrorValueModal: () =>
      dispatch(actions.hideSignalErrorValueModal()),
    onCheckCanvas: (token, date, showId) =>
      dispatch(actions.canvasCheck(token, date, showId)),
    onSearchCurrentLiveShot: (token) => dispatch(actions.searchLiveShot(token)),
    createNotification: (config) => dispatch(createNotification(config)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onFetchHeartBeat: (sso, token, onSessionTimeOut) =>
      dispatch(actions.fetchHeartBeat(sso, token, onSessionTimeOut)),
    onFetchAllLiveshotPreference: (token) =>
      dispatch(actions.fetchAllLiveshotPreference(token)),
    onFetchRoleList: (token) => dispatch(actions.fetchRoleList(token)),
    onTakeAllCanvasBlock: (
      token,
      userId,
      userFullName,
      liveshots,
      liveShotSignalSelected,
      roomId,
      currentRoom,
      liveshotSignalDuplicates,
      showMessage
    ) =>
      dispatch(
        actions.takeAllCanvasBlock(
          token,
          userId,
          userFullName,
          liveshots,
          liveShotSignalSelected,
          roomId,
          currentRoom,
          liveshotSignalDuplicates,
          showMessage
        )
      ),
    onGrabAllCanvasBlock: (
      token,
      userId,
      userFullName,
      liveshots,
      liveShotSignalSelected,
      roomId,
      showMessage,
      currentRoom,
      currentCanvas,
      outBoundFieldsNormal,
      commsFieldsNormal
    ) =>
      dispatch(
        actions.grabAllCanvasBlock(
          token,
          userId,
          userFullName,
          liveshots,
          liveShotSignalSelected,
          roomId,
          showMessage,
          currentRoom,
          currentCanvas,
          outBoundFieldsNormal,
          commsFieldsNormal
        )
      ),
    onSetActiveLiveshot: (id) => dispatch(actions.setActiveLiveshot(id)),
    onSetPressedKey: (key) => dispatch(actions.setPressedKey(key)),
    onStoreArrPosition: (arrPosition) =>
      dispatch(actions.onStoreArrPosition(arrPosition)),
    authRemove: () => dispatch(actions.authRemove()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
