import React from "react";
import Loader_img from "../../../../assets/images/loader.gif";
import "../../../shared/UI/Spinner/Spinner.css";
import { useHideScroll } from "../../../../hooks/useHideScroll";

const Loader = ({ loading = false }) => {
  useHideScroll(loading, "loader-scroll-hide");
  return loading ? (
    <div className="loader" key="loader">
      <img width="25" height="25" src={Loader_img} alt="loader" />
    </div>
  ) : null;
};

export default Loader;
