import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import ErrorMessage from "../../shared/ErrorMessage";
import "../../studiotruck/StudioTruck.css";
import venueLogo from "../../../assets/icons/venue-icon.svg";
import Select from "react-select";
import CloseIconComponent from "../../shared/UI/CloseIconComponent/CloseIconComponent";
import "./VenueModal.css";
import invertedArrow from "../../../utils/dropdownArrow/dropdownArrow";
import { venue_page_constants } from "../../../constants/PageConstants";
import "../../shared/CommonCss/CommonCss.css";
import {
  validateAndUpdatePin,
  validateFormPin,
  transformInput,
} from "../../shared/utility";
import { Input } from "antd";
import { VENUE_TYPES, MESSAGE_TEXT } from "../../../constants/PageConstants";
import VenueContactForm from "../../venueContact/VenueContactForm";
import * as Constants from "../../shared/Constants";
import { CustomInput } from "../../shared/UI/Input/CustomInput";
import { DebounceInput } from "react-debounce-input";

const { TextArea } = Input;

const VenueModal = (props) => {
  const {
    isAddVenue,
    lsId,
    onSaveStudioTruck,
    token,
    onUpdateStudioTruck,
    onDelete,
    studioTruckTypes,
    stName,
    venueStCity,
    venueStRegion,
    venueStCountry,
    stType,
    stContacts,
    studioCost,
    stDesc,
    studioId,
    stSkype,
    stQuickLink,
    stWebOther,
    stTxPath,
    onDeleteStudioTruck,
    currentUser,
    closeModal,
    stTypeId,
    poNum,
    stIfb1Pin,
    stIfb2Pin,
    stPlPin,
    openErrorBar,
    closeErrorBar,
    stCreatedOn,
    stLiveshotNotes,
  } = props;

  const [studioName, setStudioName] = useState(null);
  const [studioHourRate, setStudioHourRate] = useState(null);
  const [venueCity, setVenueCity] = useState(null);
  const [venueState, setVenueState] = useState(null);
  const [venueCountry, setVenueCountry] = useState(null);
  const [venueSkype, setVenueSkype] = useState(null);
  const [venueQuickLink, setVenueQuickLink] = useState(null);
  const [venueWebOther, setVenueWebOther] = useState(null);
  const [venueTxPath, setVenueTxPath] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [studioTruckMessage, setStudioTruckMessage] = useState(false);
  const [studioTruckModal, setStudioTruckModal] = useState(false);
  const [intlChecked, setIntlChecked] = useState(false);
  const [studioNotes, setStudioNotes] = useState(null);
  const [studioType, setStudioType] = useState(stTypeId);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [venueIsOpen, setIsOpen] = useState(false);
  const [isVenueSaveDisabled, setIsVenueSaveDisabled] = useState(true);
  const [ifb1Pin, setIfb1Pin] = useState("");
  const [ifb2Pin, setIfb2Pin] = useState("");
  const [plPin, setPlPin] = useState("");
  const [isSkypeRowVisible, setIsSkypeRowVisible] = useState(false);
  const [isLocationRowVisible, setIsLocationRowVisible] = useState(false);
  const [studioCreatedOn, setStudioCreatedOn] = useState(null);
  const [liveshotNotes, setLiveshotNotes] = useState("");

  const [venueContactDetails, setVenueContactDetail] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [isContactsValid, setContactsValid] = useState(true);

  const contactFormRef = useRef();

  const VIDEO_CONFERENCE = "VIDEO CONFERENCE";
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  useEffect(() => {
    setStudioName(stName);
    setStudioHourRate(studioCost);
    setStudioNotes(stDesc);
    setIfb1Pin(stIfb1Pin);
    setIfb2Pin(stIfb2Pin);
    setPlPin(stPlPin);
    setVenueCity(venueStCity);
    setVenueState(venueStRegion);
    setVenueCountry(venueStCountry);
    setVenueSkype(stSkype);
    setVenueQuickLink(stQuickLink);
    setVenueWebOther(stWebOther);
    setVenueTxPath(stTxPath);
    setLiveshotNotes(stLiveshotNotes);
    setPoNumber(poNum);
    setStudioCreatedOn(stCreatedOn);
    if (stType !== null) {
      const studioList = studioTruckTypes.map((option) => ({
        value: option.studioTypeId,
        key: option.studioTypeId,
        label: option.studioTypeName,
      }));
      studioList.forEach((st) => {
        if (st.label === stType) {
          handleVenueChange(st);
        }
      });
    }

    let contacts = [];
    ["", "2", "3"].forEach((contactIter) => {
      let name = stContacts["studioContact" + contactIter];
      let phone = stContacts["studioPhone" + contactIter];
      let email = stContacts["studioEmail" + contactIter];

      if (
        (name && name != "") ||
        (phone && phone != "") ||
        (email && email != "")
      ) {
        contacts.push({ name, phone, email });
      }
    });
    setContactList(contacts);
  }, [
    stName,
    stContacts,
    stType,
    studioCost,
    stDesc,
    venueStCity,
    venueStRegion,
    venueStCountry,
    stSkype,
    stQuickLink,
    stWebOther,
    stTxPath,
    poNum,
    stIfb1Pin,
    stIfb2Pin,
    stPlPin,
    stCreatedOn,
    stLiveshotNotes,
  ]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleVenueChange = (chosenValue) => {
    setStudioType(chosenValue.value);
    setSelectedVenue(chosenValue);
    if (chosenValue.label === VIDEO_CONFERENCE) {
      setVenueCity(null);
      setVenueState(null);
      setVenueCountry(null);
      setIsSkypeRowVisible(true);
      setIsLocationRowVisible(false);
    } else if (VENUE_TYPES.includes(chosenValue.label)) {
      setVenueCity(null);
      setVenueState(null);
      setVenueCountry(null);
      setVenueSkype(null);
      setVenueQuickLink(null);
      setVenueWebOther(null);
      setIsSkypeRowVisible(false);
      setIsLocationRowVisible(false);
    } else {
      setVenueSkype(null);
      setVenueQuickLink(null);
      setVenueWebOther(null);
      setIsSkypeRowVisible(false);
      setIsLocationRowVisible(true);
    }
  };

  const intlToggle = () => {
    setIntlChecked(!intlChecked);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const studioFormData = {};
    studioFormData.studioName = transformInput(studioName);
    studioFormData.studioHourRate = studioHourRate * 2;
    studioFormData.studioDesc = transformInput(studioNotes);
    studioFormData.studioIfb1pin = ifb1Pin;
    studioFormData.studioIfb2pin = ifb2Pin;
    studioFormData.studioPlpin = plPin;
    studioFormData.skype = transformInput(venueSkype);
    studioFormData.quickLink = transformInput(venueQuickLink);
    studioFormData.webOther = transformInput(venueWebOther);
    studioFormData.city = transformInput(venueCity);
    studioFormData.region = transformInput(venueState);
    studioFormData.country = transformInput(venueCountry);
    studioFormData.studioTypeId = studioType;
    studioFormData.studioTx = transformInput(venueTxPath);
    studioFormData.liveshotNote = liveshotNotes;
    studioFormData.poNumber = poNumber;

    let contactDetails = contactFormRef.current.fetchContact();
    ["", "2", "3"].forEach((contactIter, index) => {
      let contact = contactDetails && contactDetails[index];
      if (contact) {
        studioFormData["studioContact" + contactIter] = transformInput(
          contact.name
        );
        studioFormData["studioPhone" + contactIter] = transformInput(
          contact.phone
        );
        studioFormData["studioEmail" + contactIter] = contact.email;
      }
    });

    const hasErrorMsg = validateFormPin(studioFormData);
    if (hasErrorMsg) {
      openErrorBar(hasErrorMsg, true);
      return false;
    }
    studioFormData.createdBy = parseInt(userId);
    if (studioId && studioId !== 0) {
      studioFormData.updatedBy = parseInt(userId);
      studioFormData.stId = studioId;
      studioFormData.createdOn = studioCreatedOn;
      onUpdateStudioTruck(studioFormData, token, lsId);
    } else {
      onSaveStudioTruck(studioFormData, token, lsId);
    }
    closeModal();
    closeErrorBar();
  };

  let studioTruckError = null;
  if (studioTruckModal) {
    studioTruckError = (
      <div className="venue-modal-error">
        <ErrorMessage
          closeModal={() => setStudioTruckModal(false)}
          message={studioTruckMessage}
        />
      </div>
    );
  }
  //Code to set the enable /disable of save button for add and edit flows.
  useEffect(() => {
    if (isLocationRowVisible) {
      let isVenueSaveDisabledCondition =
        transformInput(studioName) &&
        selectedVenue &&
        isContactsValid &&
        transformInput(venueCity) &&
        transformInput(venueState) &&
        transformInput(venueTxPath)
          ? false
          : true;
      setIsVenueSaveDisabled(isVenueSaveDisabledCondition);
    } else {
      let isVenueSaveDisabledCondition =
        transformInput(studioName) &&
        selectedVenue &&
        isContactsValid &&
        transformInput(venueTxPath)
          ? false
          : true;
      setIsVenueSaveDisabled(isVenueSaveDisabledCondition);
    }
  }, [
    studioName,
    selectedVenue,
    isContactsValid,
    venueCity,
    venueState,
    venueCountry,
    venueTxPath,
    isLocationRowVisible,
  ]);

  return (
    <Fragment>
      {studioTruckError}
      <div className="popup-modal stcontainer">
        <div className="venue-modal-content">
          <div className="model-title-icons-row">
            <img className="venue-top-logo" src={venueLogo} alt="venue logo" />
            <h5 className="modal-title">Venue</h5>
            <CloseIconComponent
              onCloseModal={closeModal}
              classNames={"venue-close-icon"}
            />
          </div>
          <div className="venue-modal-body">
            <div className="venue-form-wrapper">
              <label>
                <span className="venue-label-star">* </span>Venue Name
              </label>
              <DebounceInput
                data-testid="studiotruck-name"
                element={CustomInput}
                overrideStyle={true}
                debounceTimeout={1500}
                autoSize={{ minRows: 1, maxRows: 2 }}
                className={`full-width liveshot-notes venue-contact-form-name venue-input venue-name-input transform-input`}
                name="studio_truck"
                autoComplete="off"
                value={studioName || ""}
                placeholder="ENTER NAME"
                onChange={(e) => setStudioName(e.target.value)}
                maxLength={Constants.INPUT_MAX_LENGTH}
              />
              <label>
                <span className="venue-label-star">* </span>Venue Type
              </label>
              {
                <Select
                  className={
                    venueIsOpen
                      ? "venue-dropdown green-bg black-text"
                      : selectedVenue
                      ? "venue-dropdown white-text grey-bg"
                      : !selectedVenue && venueIsOpen
                      ? "venue-dropdown green-bg black-text"
                      : !isAddVenue
                      ? "white-text"
                      : "venue-dropdown grey-text grey-bg"
                  }
                  classNamePrefix="vm-dropdown-styles"
                  onChange={handleVenueChange}
                  onMenuOpen={() => setIsOpen(true)}
                  onMenuClose={() => setIsOpen(false)}
                  styles={venueIsOpen ? invertedArrow : ""}
                  placeholder={
                    stType === "" || stType === null
                      ? "E.G. 30R LOCATION, SKYPE, QUICKLINK..."
                      : stType
                  }
                  value={selectedVenue}
                  cache={{ "": [{ label: "", value: null, disabled: true }] }}
                  options={studioTruckTypes.map((option) => ({
                    value: option.studioTypeId,
                    key: option.studioTypeId,
                    label: option.studioTypeName,
                  }))}
                />
              }
              <div
                className={isSkypeRowVisible ? "displayBlock" : "displayNone"}
              >
                <div>
                  <label className="width-32 margin-right-2percent">
                    Skype
                  </label>
                  <label className="width-32">Quicklink</label>
                  <label className="width-32 margin-left-2percent">
                    Web / Other
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    className="venue-input margin-right-2percent width-32 transform-input"
                    name="venue_skype"
                    autoComplete="off"
                    value={venueSkype || ""}
                    onChange={(e) => setVenueSkype(e.target.value)}
                    placeholder="ENTER"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                  <input
                    type="text"
                    className="venue-input width-32 transform-input"
                    name="venue_quicklink"
                    autoComplete="off"
                    value={venueQuickLink || ""}
                    onChange={(e) => setVenueQuickLink(e.target.value)}
                    placeholder="ENTER"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                  <input
                    type="text"
                    className="venue-input margin-left-2percent width-32 transform-input"
                    name="venue_webother"
                    autoComplete="off"
                    value={venueWebOther || ""}
                    onChange={(e) => setVenueWebOther(e.target.value)}
                    placeholder="ENTER"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                </div>
              </div>
              <div
                className={
                  isLocationRowVisible ? "displayBlock" : "displayNone"
                }
              >
                <div>
                  <label className="width-32 margin-right-2percent">
                    <span className="venue-label-star">* </span>City
                  </label>
                  <label className="width-32">
                    <span className="venue-label-star">* </span>State, Province,
                    or Region
                  </label>
                  <label className="width-32 margin-left-2percent">
                    Country
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    className="venue-input margin-right-2percent width-32 transform-input"
                    name="venue_city_state"
                    autoComplete="off"
                    value={venueCity || ""}
                    onChange={(e) => setVenueCity(e.target.value)}
                    placeholder="ENTER CITY"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                  <input
                    type="text"
                    className="venue-input width-32 transform-input"
                    name="venue_state"
                    autoComplete="off"
                    value={venueState || ""}
                    onChange={(e) => setVenueState(e.target.value)}
                    placeholder="ENTER"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                  <input
                    type="text"
                    className="venue-input margin-left-2percent width-32 transform-input"
                    name="venue_country"
                    autoComplete="off"
                    value={venueCountry || ""}
                    onChange={(e) => setVenueCountry(e.target.value)}
                    placeholder="ENTER COUNTRY"
                    maxLength={Constants.INPUT_MAX_LENGTH}
                  />
                </div>
              </div>
              <VenueContactForm
                ref={contactFormRef}
                contactList={contactList}
                setInvalidContact={(e) => {
                  setContactsValid(!e);
                }}
                onContactUpdate={setVenueContactDetail}
              ></VenueContactForm>
              <div>
                <label className="width-32 margin-right-2percent">
                  IFB 1 PIN
                </label>
                <label className="width-32">IFB 2 PIN</label>
                <label className="width-32 margin-left-2percent">PL PIN</label>
              </div>
              <div>
                <input
                  maxLength={venue_page_constants.MAX_LENGTH}
                  type="text"
                  className="venue-input margin-right-2percent width-32"
                  name="venue_ifb1Pin"
                  autoComplete="off"
                  value={ifb1Pin || ""}
                  onChange={(e) => {
                    validateAndUpdatePin(e.target.value, setIfb1Pin);
                  }}
                  pattern="[0-9]{4}"
                  placeholder="ENTER IFB 1 PIN"
                />
                <input
                  maxLength={venue_page_constants.MAX_LENGTH}
                  type="text"
                  className="venue-input width-32"
                  name="venue_ifb2Pin"
                  autoComplete="off"
                  value={ifb2Pin || ""}
                  onChange={(e) => {
                    validateAndUpdatePin(e.target.value, setIfb2Pin);
                  }}
                  pattern="[0-9]{4}"
                  placeholder="ENTER IFB 2 PIN"
                />
                <input
                  maxLength={venue_page_constants.MAX_LENGTH}
                  type="text"
                  className="venue-input margin-left-2percent width-32"
                  name="venue_plPin"
                  autoComplete="off"
                  value={plPin || ""}
                  pattern="[0-9]{4}"
                  onChange={(e) => {
                    validateAndUpdatePin(e.target.value, setPlPin);
                  }}
                  placeholder="ENTER PL PIN"
                />
              </div>
              <div>
                <label className="venue-modal-label-left">
                  <span className="venue-label-star">* </span>Tx
                </label>
              </div>
              <div>
                <DebounceInput
                  element={CustomInput}
                  overrideStyle={true}
                  debounceTimeout={1500}
                  className="venue-txpath-field full-width liveshot-notes transform-input"
                  name="tx"
                  autoComplete="off"
                  value={venueTxPath || ""}
                  placeholder="ENTER Tx"
                  onChange={(e) => setVenueTxPath(e.target.value)}
                  maxLength={1000}
                />
              </div>
              <div>
                <label className="venue-modal-label-left">Liveshot Notes</label>
              </div>
              <div className="venuemodal-liveshot-notes">
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  className="venue-txpath-field full-width liveshot-notes"
                  name="tx"
                  autoComplete="off"
                  value={liveshotNotes || ""}
                  placeholder="ENTER LIVESHOT NOTES"
                  onChange={(e) => setLiveshotNotes(e.target.value)}
                />
              </div>

              <label>PO Number</label>
              <input
                className="venue-input venue-po"
                name="po"
                placeholder="ENTER PO #"
                autoComplete="off"
                value={poNumber || ""}
                onChange={(e) => {
                  validateAndUpdatePin(e.target.value, setPoNumber);
                }}
                pattern="[0-9]"
                maxLength={15}
              />
            </div>
          </div>
          <div className="venue-modal-icons-row">
            <input
              className="venue-cancel-icon"
              type="button"
              value="Cancel"
              onClick={closeModal}
            />
            <input
              className="venue-save-icon"
              type="button"
              value="Save"
              data-testid="pin-save"
              onClick={(e) => {
                onSubmit(e);
                // Comment out for now- redunant call-- will remove in future once app is stable
                // refreshFunction();
              }}
              disabled={isVenueSaveDisabled}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    studioTruckList: state.studioTruckInfo.studioTruckList,
    showModal: state.studioTruckOperations.showModal,
    successMsg: state.studioTruckOperations.successMsg,
    currentUser: state.auth.currentUser,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetvenueAddressStatus: (token) =>
      dispatch(actions.getvenueAddressInfo(token)),
    onSaveStudioTruck: (studioFormData, token, lsId) =>
      dispatch(actions.saveStudioTruck(studioFormData, token, lsId)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),

    onUpdateStudioTruck: (studioFormData, token, lsId) =>
      dispatch(actions.updateStudioTruck(studioFormData, token, lsId)),
    onCloseCommanModal: (token) =>
      dispatch(actions.closeStudioTruckModal(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueModal);
