import React, { useEffect, useRef } from "react";
import "./DownloadMenu.css";

const DownloadMenu = (props) => {
  const { showdownload, setShowDownload, selectedItem } = props;

  const downloadMenu = [
    { id: 1, name: "Full Details" },
    { id: 2, name: "Condensed" },
    { id: 3, name: "Compose Email" },
  ];

  const useOutsideClick = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDownload(showdownload);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return (
    <ul
      ref={wrapperRef}
      className={showdownload ? "download-menu" : "hide-download"}
    >
      {downloadMenu.map((list, index) => {
        return (
          <li onClick={selectedItem} key={list.id} value={list.id}>
            {list.name}{" "}
          </li>
        );
      })}
    </ul>
  );
};

export default DownloadMenu;
