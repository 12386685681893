import React from "react";
import "./Backdrop.css";
import { useHideScroll } from "../../../../hooks/useHideScroll";

const Backdrop = (props) => {
  useHideScroll(props.show, "hide-scroll");
  return props.show ? (
    <div className="Backdrop" onClick={props.clicked}></div>
  ) : null;
};

export default Backdrop;
