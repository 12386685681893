import React, { useEffect } from "react";
import "./NotificationBarComponent.css";
import Warning_icon from "../../../../assets/icons/Warning.svg";
import User_filled_icon from "../../../../assets/icons/UserIconFilled.svg";
import close_icon from "../../../../assets/icons/Close-icon-noborder.svg";
import { ReactComponent as Refresh_icon } from "../../../../assets/icons/refresh-icon.svg";
import Button from "../../UI/Button/Button";

const NotificationBarComponent = ({
  classNames,
  closeErrorBar,
  errorMessage,
  isError,
  isWarning,
  isFixed = false,
  show,
  timeoutMs = 5000,
  onRefresh,
}) => {
  useEffect(() => {
    if (show && timeoutMs != 0) {
      const timeout = setTimeout(() => closeErrorBar(), timeoutMs);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [show]);
  return show == undefined || show ? (
    <>
      <div
        className={
          (isError
            ? "blatant-error"
            : isWarning
            ? "blatant-warning"
            : "blatant-success") + (isFixed ? " position-fixed" : "")
        }
      >
        <img
          className={`blatant-icon${isWarning ? "-warning" : ""}`}
          src={isWarning ? Warning_icon : User_filled_icon}
          alt="Warning_icon"
        />
        <span className={`blatant-text${isWarning ? "-warning" : ""}`}>
          {errorMessage}
        </span>
        <div className="blatant-icons">
          {onRefresh && (
            <Button btnType="refresh_btn" onClick={onRefresh}>
              <Refresh_icon className="tvright-space" />
              <label>REFRESH</label>
            </Button>
          )}
          <img
            className={`ErrorCloseIcon ${
              isWarning ? "warning" : ""
            } ${classNames}`}
            src={close_icon}
            alt="close_icon"
            onClick={closeErrorBar}
          />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default NotificationBarComponent;
